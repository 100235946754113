@font-face {
	font-family: 'Geist Mono';
	src: url('/mm/fonts/GeistMono/GeistMono-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Geist Mono';
	src: url('/mm/fonts/GeistMono/GeistMono-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Geist Mono';
	src: url('/mm/fonts/GeistMono/GeistMono-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Geist Mono';
	src: url('/mm/fonts/GeistMono/GeistMono-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
